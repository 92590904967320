import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";

function convertToSlug(Text)
  {
      return Text
          .toString()
          .toLowerCase()
          .replace(/ /g,'-')
          .replace(/[^\w-]+/g,'')
          ;
  }




const ComponentName = ({data}) => {


    const posts = data.posts;
    const companyInfo = data.company.data;
    const postTitle = `Inspire from ${companyInfo.pageHeading ? companyInfo.pageHeading.toString().toLowerCase() : 'great emails'} made and used by ${companyInfo.Name} `
    const postDescription = `Check out ${data.posts.totalCount} email designs created and send by ${companyInfo.Name} to their users for inspiration. Find patterns and copy that you could use for your next email with Emailkick Inspiration.`
    return (
        <Layout logoText="Inspirations">
            <SEO 
                        description={`${postDescription}`}
                        title={`${postTitle}`} 
                        author="Athul Suresh"
                        />

                    <div className="border-b border-gray-200  bg-white pt-14 pb-2">
                        <div className="max-w-7xl mx-auto  px-4 lg:px-0">
                            

                            <div className="flex pt-4 pb-2 text-sm text-gray-400">
                                <a href="/email-inspirations/" className="text-gray-500 mr-2 inline-block hover:underline hover:text-gray-700 ">Inspiration</a> /  <span className="text-gray-900 ml-2 inline ">{companyInfo.Name}</span>
                            </div>

                           
                        </div>
                    </div>


            <div className=" shadow-sm py-10 border-b border-gray-200">
                
                <div className="max-w-7xl mx-auto text-left">
                    <h1 className="font-bold text-red-750 px-4 lg:px-0">{companyInfo.pageHeading ? companyInfo.pageHeading : 'Good looking emails'} from <span className="underline">{companyInfo.Name}</span> for inspiration</h1>
                    <p className="mt-2 text-base text-gray-500 px-4 lg:px-0">Email designs made by {companyInfo.Name}</p>
                </div>
            </div>

            <div className="py-12 bg-gray-50">
                <div className="max-w-7xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2  gap-2 md:gap-8 px-4 lg:px-0">

                        { posts.edges.filter(a => a.node.data.Subject != null ).map(epost => (
                            <div>
                            <Link to={`/email-inspirations/${convertToSlug(epost.node.data.Name__from_Companies_)}/${convertToSlug(epost.node.data.Subject)}/`} key={epost.node.id} className="block rounded-lg border border-gray-200 shadow-sm bg-white duration-75 transform scale-100 hover:scale-105 hover:shadow-lg mb-4">
                                {epost.node.data.Attachments ? <img src={epost.node.data.Attachments[0].url} style={{ maxHeight:'400px' }} className="w-full object-cover object-top border-b border-gray-100 rounded-t-md"></img> : null }
                                <div className="px-2 py-3">
                                    <div className="text-sm">{epost.node.data.Subject}</div>
                                    <div className="text-gray-600 mt-1">{epost.node.data.Name__from_Companies_ ? epost.node.data.Name__from_Companies_[0] : null}</div>
                                </div>
                            </Link>
                            </div>
                        ))}

                       
                    </div>
                </div>
                
            </div>
        </Layout>
    )
}



export const query = graphql`
  query ($id: String){
    posts: allAirtable(
      filter: {table: {eq: "Emails"}, data: {Company: {in: [$id]}}}
    ) {
      edges {
        node {
          id
          data {
            Name__from_Companies_
            Subject
            website__from_Companies_
            Company
            Attachments {
              filename
              id
              url
            }
            EmailType
          }
        }
      }
      totalCount
    }
    company: airtable(recordId: {eq: $id}) {
      id
      data {
        website
        Name
        pageHeading
      }
    }
  }`

export default ComponentName